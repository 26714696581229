import React from "react";
// import style from "./Resume.module.css";
import html from "../constants/HTML.png";
import js from "../constants/JS.jpg";
import css from "../constants/CSS.png";
import express from "../constants/ExpressJS.png";
import nest from "../constants/NestJS.png";
import node from "../constants/Node3.png";
import react from "../constants/React.png";
import redux from "../constants/Redux.png";
import sequelize from "../constants/Sequelize.png";
import typescript from "../constants/typescript.png";
import postgre from "../constants/postgreSQL.png";


const Resume = ({ data }) => {
  if (data) {
    var skillmessage = data.skillmessage;
    var education = data.education.map(function (education) {
      return (
        <div key={education.school}>
          <a
            href="https://www.soyhenry.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>{education.school}</h3>
          </a>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p >{education.description}</p>
        </div>
      );
    });
    var work = data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p>{work.description}</p>
        </div>
      );
    });
    var skills = data.skills.map(function (skills) {
      var className = "bar-expand " + skills.name.toLowerCase();
      return (
        <li key={skills.name}>
          <span style={{ width: skills.level }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });
  }

  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div>
            <ul className="skillaaa">
              <li>
                <div className="container">
                  <h2 className="htmlTxt">HTML</h2>
                  <img className="imgHtml" src={html} alt="html" />
                </div>
              </li>
              <li>
                <div className="container">
                  <h2 className="jsTxt">JavaScript</h2>
                  <img className="imgJs" src={js} alt="js" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="cssTxt">CSS</h2>
                  <img className="imgJs" src={css} alt="css" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="typeTxt">TypeScript</h2>
                  <img className="imgJs" src={typescript} alt="typescript" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="reactTxt">React</h2>
                  <img className="imgJs" src={react} alt="react" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="reduxTxt">Redux</h2>
                  <img className="imgJs" src={redux} alt="redux" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="nativeTxt">React Native</h2>
                  <img className="imgJs" src={react} alt="native" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="expressTxt">Express</h2>
                  <img src={express} alt="express" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="nestTxt">NestJS</h2>
                  <img className="imgJs" src={nest} alt="nest" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="nodeTxt">NodeJS</h2>
                  <img className="imgNode" src={node} alt="node" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="seqTxt">Sequelize</h2>
                  <img className="imgSeq" src={sequelize} alt="sequelize" />
                </div>
              </li>
              <li className="html">
                <div className="container">
                  <h2 className="posTxt">PostgreSQL</h2>
                  <img className="imgPos" src={postgre} alt="postgreSQL" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
